import { type ReactElement } from 'react';
import { titleCase } from '~/utils/stringTransforms';
import Tooltip from '~/components/tailgrids/Tooltip';
import ConditionalWrapper from '~/components/ConditionalWrapper';
import { LucideCircle, LucideCircleDashed, LucideCircleDot, LucideCircleDotDashed } from 'lucide-react';

export function EpisodeStatusIndicator({
  episodeStatus,
  colorClass = 'text-slate-400',
  isNavBar = false,
  enableToolTip = true,
  position = 'right',
}: {
  episodeStatus: string;
  colorClass?: string;
  isNavBar?: boolean;
  enableToolTip?: boolean;
  position?: 'right' | 'top' | 'bottom' | 'left';
}): ReactElement {
  episodeStatus = episodeStatus?.toLowerCase() ?? '';
  let episodeIcon: string | ReactElement;
  switch (episodeStatus) {
    case 'active':
    case 'extended':
      episodeIcon = <LucideCircleDot className="h-[16px] w-[16px] stroke-[4px]" />;
      colorClass = 'text-indigo-600';
      break;
    case 'pending':
      episodeIcon = <LucideCircleDotDashed className="h-[16px] w-[16px] stroke-[1.5px]" />;
      break;
    case 'closed':
      episodeIcon = <LucideCircle className="h-[16px] w-[16px] stroke-[1.5px]" />;
      break;
    case 'referred':
      episodeIcon = <LucideCircleDashed className="h-[16px] w-[16px] stroke-[1.5px]" />;
      break;
    default:
      episodeIcon = '';
      break;
  }
  return (
    <ConditionalWrapper
      condition={enableToolTip}
      wrapper={children => (
        <Tooltip tooltipsText={titleCase(episodeStatus) + ' Episode'} position={position} colorClass={'bg-slate-200'}>
          {children}
        </Tooltip>
      )}
    >
      <span
        className={`text-[24px] ${isNavBar ? 'text-white' : colorClass} ${episodeStatus === 'referred' ? '-mt-[1px]' : '-mt-[1px]'}`}
      >
        {episodeIcon}
      </span>
    </ConditionalWrapper>
  );
}
